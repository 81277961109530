export default {
  number: [
    { required: true, message: '请输入库位编号', trigger: 'change' },
    { max: 32, message: '超出最大长度 (32)', trigger: 'change' },
  ],
//   row_number: [
//     { required: true, message: '请输入行号', trigger: 'change' },
//   ],
//   column_number: [
//     { required: true, message: '请输入列号', trigger: 'change' },
// ],
//   layer_number: [
//     { required: true, message: '请输入层号', trigger: 'change' },
//   ],
  reservoir_area: [{ required: true, message: '请选择库区', trigger: 'change' }],
  remark: [{ max: 256, message: '超出最大长度 (256)', trigger: 'change' }],
}